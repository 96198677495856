
// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { FaLeaf, FaBox, FaInfoCircle } from 'react-icons/fa';
import { MdOutlineAccessTime } from 'react-icons/md';
import logo from "../assets/logo1.webp";
import { Link } from 'react-router-dom';


//main section
import { FaShippingFast, FaShoppingBag, FaStore } from 'react-icons/fa';
import mainsection from "../assets/women1.webp";
import { FaPlay } from 'react-icons/fa';


//APPDOWNLOAD SECTION

import mobileAppImage from '../assets/download.webp'; // Make sure to replace with your actual image path
import profile1 from '../assets/women1.webp'; // Replace with actual paths
import profile2 from '../assets/women1.webp';
import profile3 from '../assets/women1.webp';
import profile4 from '../assets/women1.webp';
import profile5 from '../assets/women1.webp';
import './AppDownload.css';
import { FaGooglePlay, FaApple } from 'react-icons/fa';


//BEST DELIVERED CATEGORIES

import saladImage from '../assets/salad.webp'; // Placeholder, replace with your actual image
import drinkImage from '../assets/drinks.webp'; // Placeholder, replace with your actual image
import friesImage from '../assets/fries.webp'; // Placeholder, replace with your actual image
import masala from '../assets/masalachicken.jpg';
import beverages from '../assets/softbeverages.jpg';
import french from '../assets/french.png';


//HOW WE SERVE

import packagingImage from '../assets/serve-hot.webp'; // Placeholder, replace with your actual image
import loveImage from '../assets/women1.webp'; // Placeholder, replace with your actual image
import serveImage from '../assets/package.webp'; // Placeholder, replace with your actual image
import packging from '../assets/packaging.jpg';
import lovepackage from '../assets/package love.jpg';
import hot from '../assets/hotapitate.jpg';


//PROMOTIONAL OFFERS
import offer from '../assets/02nov10.jpg'


//SUPPORT CSS
import '../style/support.css';
import wadhawani from '../assets/wadhawani.png';
import startupindia from '../assets/startup-removebg-preview.png';
import awsamazon from '../assets/amazonaws.png';
import razorpay from '../assets/razorpay-removebg-preview.png';
import microsoft from '../assets/microsoft-removebg-preview.png';



//RESTAURNAT SHOWCASE


import starbucksImage from '../assets/starbucks.webp'; // Placeholder, replace with your actual image
import dominosImage from '../assets/mcdonalds.webp'; // Placeholder, replace with your actual image
import mcdonaldsImage from '../assets/kfc.webp'; // Placeholder, replace with your actual image
// Add other restaurant images similarly...
import '../style/Restaurantshowcase.css';


//FOOTER 
import { FaPinterestP, FaInstagram, FaTwitter, FaFacebookF } from 'react-icons/fa';
import Privacy from '../components/Privacy';
// import logo from "../assets/logo1.webp";

const Header = () => {


    //header functionality
    const [menuOpen, setMenuOpen] = useState(false); // State to toggle mobile menu
    const [activeMenu, setActiveMenu] = useState(''); // State to track active menu

    // Menu items
    const menu = [
        { title: "Menu", link: "/menu", icon: <FaLeaf /> },
        { title: "Offers", link: "/offers", icon: <FaBox /> },
        { title: "Services", link: "/services", icon: <MdOutlineAccessTime /> },
        { title: "Restaurants", link: "/restaurants", icon: <FaInfoCircle /> },
    ];

    // Set active menu based on current URL
    useEffect(() => {
        const currentPath = window.location.pathname;
        const active = menu.find((item) => item.link === currentPath);
        if (active) {
            setActiveMenu(active.title);
        }
    }, []);

    // Handle menu click
    const handleMenuClick = (title) => {
        setActiveMenu(title); // Set active menu
        setMenuOpen(false); // Close mobile menu after clicking
    };

    //main section functionality
    const features = [
        {
            icon: <FaShippingFast className="text-white text-3xl" />,
            title: 'Fast delivery',
            description: 'Promised delivery within 30 mins',
        },
        {
            icon: <FaShoppingBag className="text-white text-3xl" />,
            title: 'Pick up',
            description: 'Pickup delivery at your doorstep',
        },
        {
            icon: <FaStore className="text-white text-3xl" />,
            title: 'Dine in',
            description: 'Enjoy your food fresh and crispy',
        },
    ];

    const circle = {
        borderRadius: '80px',
    }


    //APP DOWNLOAD SECTION FUNCTIONlity
    const [profiles] = useState([profile1, profile2, profile3, profile4, profile5]);

    //BEST DELIVERED VCATEGORIES FUNCTUONALITY

    const categories = [
        {
            id: 1,
            name: 'Salads',
            description: 'Fresh and healthy salads.',
            image: saladImage,
        },
        {
            id: 2,
            name: 'Drinks',
            description: 'Refreshing beverages.',
            image: drinkImage,
        },
        {
            id: 3,
            name: 'Fries',
            description: 'Crispy and delicious fries.',
            image: friesImage,
        },
    ];


    //HOW WE SERVE FUNCTIONALITY
    const services = [
        {
            id: 1,
            title: 'Automated Packaging',
            description: '100% environment-friendly packaging.',
            image: packagingImage,
        },
        {
            id: 2,
            title: 'Packed with Love',
            description: 'We make sure you get the best experience.',
            image: loveImage,
        },
        {
            id: 3,
            title: 'Serve hot Appetite',
            description: 'Delicious hot meals within 20 mins.',
            image: serveImage,
        },
    ];



    //RESTAURANT SHOWCASE
    const restaurants = [
        { id: 1, image: starbucksImage, name: 'Starbucks' },
        { id: 2, image: dominosImage, name: 'Domino\'s' },
        { id: 3, image: mcdonaldsImage, name: 'McDonald\'s' },
        // Add other restaurants similarly...
    ];




    return (

        <>
            {/* //header UI  */}

            <header className="bg-light-grey text-black p-4 fixed w-full top-0 left-0 z-10 shadow-sm">
                <div className="container mx-auto flex justify-between items-center">
                    {/* Logo */}
                    <div className="text-2xl font-bold">
                        <img src="logo" alt="logo" className="h-16 w-auto px-10" />
                    </div>

                    {/* Desktop Navigation */}
                    <nav className="hidden md:flex space-x-16">
                        {menu.map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className={`relative hover:text-green font-bold transition-all duration-300 ${activeMenu === item.title ? 'text-green' : ''
                                    }`}
                                onClick={() => handleMenuClick(item.title)}
                            >
                                {item.title}
                                {/* Underline effect for active menu */}
                                <span
                                    className={`absolute left-0 -bottom-1 h-0.5 bg-green transition-all duration-300 ${activeMenu === item.title ? 'w-full' : 'w-0'
                                        }`}
                                ></span>
                            </a>
                        ))}
                    </nav>

                    {/* Buttons */}
                    <div className="flex items-center space-x-4">
                        <div className="mt-2">
                            <button className="bg-white text-black border-2 border-green w-40 h-8 rounded font-semibold hover:bg-green hover:text-white text-center">
                                Download Our App
                            </button>
                        </div>
                        <div className="mt-2">
                            <a href="https://onboarding.thegenie.in/" target="_blank" rel="noopener noreferrer">
                                <button className="bg-green text-white w-40 h-8 rounded font-semibold hover:bg-white hover:text-black hover:border-2 hover:border-green text-center">
                                    Partner With Us
                                </button>
                            </a>
                        </div>
                    </div>

                    {/* Hamburger Menu for Mobile */}
                    <button
                        className="md:hidden text-black"
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 mt-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                </div>

                {/* Mobile Navigation */}
                {menuOpen && (
                    <nav className="md:hidden bg-light-grey mt-4">
                        {menu.map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className={`block py-2 px-4 hover:text-green font-bold ${activeMenu === item.title ? 'text-green' : ''
                                    }`}
                                onClick={() => handleMenuClick(item.title)}
                            >
                                {item.title}
                            </a>
                        ))}
                    </nav>
                )}
            </header>


            {/* main section code UI */}

            < section className="bg-white py-2" >
                <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
                    <div className="md:w-1/2">
                        <h1 className="text-5xl md:text-5xl font-bold mb-4">
                            Fastest <br /> <span className="text-red">Delivery & <br /></span> Easy <span className="text-green">Pickup</span>
                        </h1>
                        <p className="text-gray-600 mb-6">
                            When you are too lazy to cook, we are just a click away!
                        </p>
                        <div className="flex items-center space-x-4 mb-6 px-24">
                            <div className="flex">
                                <input
                                    type="text"
                                    placeholder="Find Restaurants"
                                    className="bg-gray-200 text-dark px-4 py-2 rounded-l-md w-56"
                                />
                                <button className="bg-green text-white px-4 py-2 rounded-r-md">Search</button>
                            </div>
                            <div className=" cursor-pointer flex items-center  space-x-2 rounded-md">
                                <div className="relative flex items-center justify-center  h-12 w-20   bg-green rounded-full" style={circle}>
                                    <FaPlay className="text-white flex items-center" />
                                </div>
                                <span className="text-xl font-semibold">How to order</span>
                            </div>

                        </div>
                    </div>
                    <div className=" flex  justify-center items-center  md:justify-center ">
                        <img src={mainsection} alt="Fast Delivery" className="w-2/3 mt-28 md:w-full rounded-full" />
                    </div>
                </div>


                <div className="container bg-light-grey mx-auto flex flex-col md:flex-row items-center justify-between mt-8 px-20 ">
                    {features.map((feature, index) => (
                        <div key={index} className="flex items-center mt-4 mb-4 md:mb-0 md:mr-4">
                            <div className="flex items-center justify-center w-12 h-12 bg-green rounded-full mr-4">
                                {feature.icon}
                            </div>
                            <div>
                                <h3 className="text-xl font-bold">{feature.title}</h3>
                                <p>{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

            </section >


            {/* app download section page */}
            <section className="bg-white py-10 ">

                <div>
                    <div className="container mx-auto clip flex flex-col lg:flex-row items-center justify-between px-4 relative">
                        <div className="lg:w-1/2 mb-8 lg:mb-0 mt-10 z-10">
                            <img
                                src={mobileAppImage}
                                alt="Mobile App"
                                className="mobile rounded lg:w-2/3 mx-auto"
                            />
                        </div>
                        <div className="lg:w-1/2 text-center lg:text-left">
                            <h2 className="text-3xl text-white font-bold mb-4">Download our Mobile App</h2>
                            <div className="flex justify-center lg:justify-start space-x-2 mb-6">
                                {profiles.map((profile, index) => (
                                    <img
                                        key={index}
                                        src={profile}
                                        alt={`Profile ${index + 1}`}
                                        className="w-10 h-10 rounded-full border-2 border-green -ml-2"
                                    />
                                ))}
                            </div>
                            <div className="flex justify-center lg:justify-start space-x-4">
                                <a
                                    href="#"
                                    className="flex items-center bg-green border-white  border-2 text-white px-4 py-2 rounded-full  transition"
                                >
                                    <FaGooglePlay className="text-xl mr-2" /> Play Store
                                </a>
                                <a
                                    href="#"
                                    className="flex items-center bg-green border-2 border-green-500 text-white px-4 py-2 rounded-full  transition"
                                >
                                    <FaApple className="text-xl mr-2" /> App Store
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>



            {/* BEST DELIVERED CATEGORIES UI */}

            <section className="bg-white py-12">

                <div className="max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8 ">


                    <div className='flex space-x-80'>
                        <div className="flex text-center space-x-20">
                            <h2 className="text-3xl font-extrabold ml-28 text-gray-900 ">
                                Our <span className="text-green">Best Delivered </span> <br />
                                <span className='mr-28'>Categories</span>
                            </h2>
                        </div>
                        <div className='ml-60'>
                            <p className="text-lg text-gray-600 flex">
                                It's not just about bringing you good food from restaurants, <br />
                            </p>
                            <span className="text-lg text-gray-600 flex">we deliver you experience.</span>
                        </div>
                    </div>

                    {/* Categories */}
                    <div className="flex justify-around mt-10">
                        {/* Category 1: Masala Chicken */}
                        <div className="flex flex-col items-center">
                            <div className="w-32 h-32 rounded-full border-4 border-yellow-400 flex items-center justify-center mb-4">
                                <img src={masala} alt="Masala Chicken" className="w-40 h-32  object-cover rounded-full" />
                            </div>
                            <span className="text-lg font-semibold">Masala Chicken</span>
                        </div>

                        {/* Category 2: Soft Beverages */}
                        <div className="flex flex-col items-center">
                            <div className="w-32 h-32 rounded-full border-4 border-orange-400 flex items-center justify-center mb-4">
                                <img src={beverages} alt="Soft Beverages" className="w-32 h-32 object-cover rounded-full" />
                            </div>
                            <span className="text-lg font-semibold">Soft Beverages</span>
                        </div>

                        {/* Category 3: French Fries */}
                        <div className="flex flex-col items-center">
                            <div className="w-32 h-32 rounded-full border-4 border-green flex items-center justify-center mb-4">
                                <img src={french} alt="French Fries" className="w-36 h-36 object-cover rounded-full" />
                            </div>
                            <span className="text-lg font-semibold">French Fries</span>
                        </div>
                    </div>
                </div>

            </section >


            {/* HOW WE SERVE  */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Title Section */}
                <div className="text-center mb-10">
                    <h2 className="text-3xl font-extrabold text-gray-900">
                        Our <span className="text-green">Key Features</span>
                    </h2>
                    <p className="mt-4 text-lg text-gray-600">
                        We ensure to deliver high quality with care, speed, and love.
                    </p>
                </div>

                {/* Features Section */}
                <div className="flex justify-center items-center space-x-10">
                    {/* Feature 1: Automated Packaging */}
                    <div className="flex flex-col items-center">
                        <div className="w-32 h-32 rounded-full border-4 border-green flex items-center justify-center mb-4">
                            <img src={packging} alt="Automated Packaging" className="w-32 h-32 object-cover rounded-full" />
                        </div>
                        <span className="text-lg font-semibold text-center">Automated Packaging</span>
                        <p className="text-sm text-gray-500 text-center mt-2">100% environment friendly packaging</p>
                    </div>

                    {/* Feature 2: Packed with Love */}
                    <div className="flex flex-col items-center">
                        <div className="w-32 h-32 rounded-full border-4 border-green flex items-center justify-center mb-4">
                            <img src={lovepackage} alt="Packed with Love" className="w-32 h-32 object-cover rounded-full" />
                        </div>
                        <span className="text-lg font-semibold text-center">Packed with Love</span>
                        <p className="text-sm text-gray-500 text-center mt-2">We deliver the best experiences</p>
                    </div>

                    {/* Feature 3: Serve Hot Appetite */}
                    <div className="flex flex-col items-center">
                        <div className="w-32 h-32 rounded-full border-4 border-green flex items-center justify-center mb-4">
                            <img src={hot} alt="Serve Hot Appetite" className="w-32 h-32 object-cover rounded-full" />
                        </div>
                        <span className="text-lg font-semibold text-center">Serve Hot Appetite</span>
                        <p className="text-sm text-gray-500 text-center mt-2">Promise to deliver within 30 mins</p>
                    </div>
                </div>

            </div>

            {/* PROMOTIONALA OFFERS PAGE */}

            <div className='flex mt-20 justify-around'>
                <div className='ml-20 rounded-full px-20'>
                    <img src={offer} className='h-96 min-w-96 rounded-md' alt="offer" />
                </div>
                <div className='text-start mt-5'>
                    <h1 className='text-3xl font-bold font-serif'>Pickup food from <br /> <span>the Restaurants</span>  <br /> <span className='text-6xl'>you LOVE!</span></h1>
                    <p className='mt-5'>A Super quick and hassle-free food Takeout & delivery experience. <br />
                        <br />


                        Cheap, Fast & Hot Food. Instant Pickups!</p>
                </div>

            </div>


            {/* SUPPORT UI */}

            <div className='bg-light-grey mt-3'>
                <h1 className='mt-10  text-2xl font-serif mb-5'>Supported By</h1>
                <div className="marquee-container">
                    <div className="marquee-content">
                        <img src={startupindia} alt="Image 1" className="marquee-image" />
                        <img src={awsamazon} alt="Image 2" className="marquee-image" />
                        <img src={razorpay} alt="Image 3" className="marquee-image" />
                        <img src={microsoft} alt="Image 4" className="marquee-image" />
                        <img src={wadhawani} alt="Image 5" className="marquee-image" />
                    </div>
                </div>
            </div>

            {/* RESTAURANT SHOWCASE */}

            <section className="relative bg-green text-white py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-4xl font-extrabold mb-4">
                        Enjoy <span className="text-yellow-400">3000+</span> foods from <span className="text-yellow-400">400</span> Restaurants
                    </h2>
                    <button className="mt-6 px-6 py-3 bg-black text-white font-semibold rounded-full shadow-lg hover:bg-gray-800">
                        Order Now
                    </button>
                    <div className="mt-10 flex flex-wrap justify-center gap-8">
                        {restaurants.map((restaurant) => (
                            <div key={restaurant.id} className="flex flex-col items-center">
                                <img src={restaurant.image} alt={restaurant.name} className="w-20 h-20 mb-2 rounded-full bg-white p-2" />
                                <span className="text-sm">{restaurant.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </section>



            {/* FOOTER UI  */}


            <footer className="bg-pink-100 py-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-2 sm:grid-cols-4 gap-8">
                    <div>
                        <img src={logo} alt="" />
                        <p className="mt-2 text-gray-600">Continue Genie 2025 all rights reserved</p>
                        <h4 className="mt-6 text-lg font-bold text-red-600">Follow Us On</h4>
                        <div className="flex space-x-4 mt-4 justify-center cursor-pointer">
                            <FaPinterestP className="text-gray-600 text-2xl hover:text-green" />
                            <FaInstagram className="text-gray-600 text-2xl hover:text-green" />
                            <FaTwitter className="text-gray-600 text-2xl hover:text-green" />
                            <FaFacebookF className="text-gray-600 text-2xl hover:text-green" />
                        </div>
                    </div>
                    <div className='text-start'>
                        <h3 className="text-xl font-bold text-green ml-28">Menu</h3>
                        <ul className="mt-2 space-y-2 cursor-pointer ml-28">
                            <li className="text-gray-600">Home</li>
                            <li className="text-gray-600">Offers</li>
                            <li className="text-gray-600">Service</li>
                            <li className="text-gray-600">Restaurants</li>
                        </ul>
                    </div>
                    <div className='text-start'>
                        <h3 className="text-xl font-bold text-green">Information</h3>
                        <ul className="mt-2 space-y-2">
                            {/* <a href=""> <li className="text-gray-600">Privacy Policy</li></a> */}
                            <Link to="/privacy">
                                <li className="text-gray-600">Privacy Policy</li>
                            </Link>
                            {/* <Link to="/privacy">Privacy Policy</Link> */}
                            {/* <a href=""> <li className="text-gray-600">Terms & Conditions</li></a> */}
                            <Link to="/terms" target='blank'>

                                <li className="text-gray-600">Terms & Conditions</li>
                            </Link>

                            <li className="text-gray-600">Refund & Cancellation</li>
                            <li className="text-gray-600">Investors</li>
                            <li className="text-gray-600">About Us</li>
                            <li className="text-gray-600">Partner With Us</li>
                        </ul>
                    </div>
                    <div className='text-start'>
                        <h3 className="text-xl font-bold text-green">Contact</h3>
                        <ul className="mt-2 space-y-2">
                            <li className="text-gray-600"> +91 8667211896</li>
                            <li className="text-gray-600">Get In Touch With Us</li>
                            <li className="text-gray-600">getintouch@thegenie.in</li>
                            <li className="text-gray-600">GENERIC TECHNOLOGIES PRIVATE LIMITED <br />

                                No.32, Arcot Road,

                                Virugambakkam,

                                Chennai - 600092.

                                India</li>
                        </ul>
                    </div>
                </div>
            </footer>


        </>
    );
};

export default Header;



// const handleInputChange = (event) => {
//     setSearchInput(event.target.value);
// };

{/* <div className="space-x-4"> */ }
{/* <input
                        type="text"
                        value={searchInput}
                        onChange={handleInputChange}
                        placeholder="Search..."
                        className="hidden md:block bg-white text-dark px-3 py-1 rounded border border-green"
                    /> */}
{/* <img
                        src={user}
                        alt="User"
                        className="w-10 h-10 rounded-full"
                    />
                    <a href="#" className="hover:text-green hidden md:block">Bhuvanesan S</a> */}

{/* </div> */ }
