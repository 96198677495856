// Privacy.js

import React, { useState, useEffect } from 'react';
import { FaLeaf, FaBox, FaInfoCircle } from 'react-icons/fa';
import { MdOutlineAccessTime } from 'react-icons/md';
import logo from "../assets/logo1.webp";
import { FaPinterestP, FaInstagram, FaTwitter, FaFacebookF } from 'react-icons/fa';

// import { Link } from 'react-router-dom';

const Privacy = () => {

    //header functionality
    const [menuOpen, setMenuOpen] = useState(false); // State to toggle mobile menu
    const [activeMenu, setActiveMenu] = useState(''); // State to track active menu

    // Menu items
    const menu = [
        { title: "Menu", link: "/menu", icon: <FaLeaf /> },
        { title: "Offers", link: "/offers", icon: <FaBox /> },
        { title: "Services", link: "/services", icon: <MdOutlineAccessTime /> },
        { title: "Restaurants", link: "/restaurants", icon: <FaInfoCircle /> },
    ];

    // Set active menu based on current URL
    useEffect(() => {
        const currentPath = window.location.pathname;
        const active = menu.find((item) => item.link === currentPath);
        if (active) {
            setActiveMenu(active.title);
        }
    }, []);

    // Handle menu click
    const handleMenuClick = (title) => {
        setActiveMenu(title); // Set active menu
        setMenuOpen(false); // Close mobile menu after clicking
    };

    const mailId = 'Email: koushikraj@generictechltd.com'; // Replace with your email address

    const handleMailClick = () => {
        window.location.href = `mailto:${mailId}`;
    };
    return (

        <>
            < header className="bg-light-grey text-black p-4 fixed w-full top-0 left-0 z-10 shadow-sm" >
                <div className="container mx-auto flex justify-between items-center">
                    {/* Logo */}
                    <div className="text-2xl font-bold">
                        <img src={logo} alt="logo" className="h-16 px-10 w-auto" />
                    </div>

                    {/* Desktop Navigation */}
                    <nav className="hidden md:flex space-x-16">
                        {menu.map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className={`relative hover:text-green font-bold transition-all duration-300 ${activeMenu === item.title ? 'text-green' : ''
                                    }`}
                                onClick={() => handleMenuClick(item.title)}
                            >
                                {item.title}
                                {/* Underline effect for active menu */}
                                <span
                                    className={`absolute left-0 -bottom-1 h-0.5 bg-green transition-all duration-300 ${activeMenu === item.title ? 'w-full' : 'w-0'
                                        }`}
                                ></span>
                            </a>
                        ))}
                    </nav>

                    {/* Buttons */}
                    <div className="flex items-center space-x-4">
                        <div className='mt-2'>
                            <button className='bg-white text-black border-2 border-green w-40 h-8 rounded font-semibold hover:bg-green hover:text-white text-center'>
                                Download Our App
                            </button>
                        </div>
                        <div className='mt-2'>
                            <a href="https://onboarding.thegenie.in/" target="_blank" rel="noopener noreferrer">
                                <button className='bg-green text-white w-40 h-8 rounded font-semibold hover:bg-white hover:text-black hover:border-2 hover:border-green text-center'>
                                    Partner With Us
                                </button>
                            </a>
                        </div>
                    </div>

                    {/* Hamburger Menu for Mobile */}
                    <button
                        className="md:hidden text-black"
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>

                {/* Mobile Navigation */}
                {
                    menuOpen && (
                        <nav className="md:hidden bg-light-grey mt-4">
                            {menu.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.link}
                                    className={`block py-2 px-4 hover:text-green font-bold ${activeMenu === item.title ? 'text-green' : ''
                                        }`}
                                    onClick={() => handleMenuClick(item.title)}
                                >
                                    {item.title}
                                </a>
                            ))}
                        </nav>
                    )
                }
            </header >


            <div className="privacy-policy-container mt-36 ml-72">
                <h1 className="text-5xl font-bold">Privacy Policy</h1>
                <h4 className='mt-3 font-bold text-3xl'>The Genie| Generic Technologies Private Limited</h4>
                <h1 className='font-bold mt-2'>Updated from 6th October 2023.</h1>


                <h3 className='mt-5 text-2xl font-bold'>Introduction</h3>
                <h1 className='mt-1'>Welcome to 'The Genie'! Your privacy and the security of your personal data are of paramount importance to us. This <br /> privacy policy is intended to transparently inform you about the way we handle your personal data when you interact with <br /> our website or use any of our services, regardless of where you visit or use them from.</h1>

                <h1 className='mt-8'>This privacy policy applies to 'The Genie' website, mobile applications, and all associated services (hereafter referred to <br /> as our 'Services'). By engaging with our Services, you acknowledge that you have read and understood this privacy <br /> policy, specifically how your personal data is collected, used, and processed by us. If you do not agree with the terms of <br /> this privacy policy, we ask that you refrain from using our Services.</h1>


                <h1 className='mt-8'>
                    We aim to provide you with a comprehensive understanding of our data collection and processing practices through this <br /> privacy policy, which includes any data you may furnish when you register for our service, log into your account, make a <br /> transaction, or participate in a competition.
                </h1>

                <h1 className='mt-8'>'The Genie' is the data controller and is responsible for your personal data. In this privacy policy, references to 'you' <br /> mean the individual accessing or using the Service, or the company, or other legal entity on behalf of which such <br /> individual is accessing or using the Service, as applicable. 'Company', 'we', 'us', or 'our' are references to 'The Genie'.</h1>

                <h1 className='mt-8'>
                    Our contact details are as follows: <br />
                    Full name of legal entity: GENERIC TECHNOLOGIES PRIVATE LIMITED <br />
                    Email address: getintouch@thegenie.in <br />
                    Postal address: No.32, Arcot Road, <br />
                    Virugambakkam, <br />
                    Chennai - 600092. <br />
                    India
                </h1>

            </div>

            <div className='ml-72'>
                <h3 className='mt-10 font-bold text-2xl'>Data Collection</h3>

                <h1 className='mt-2'>The information you provide is crucial for us to deliver personalized and efficient service. This section outlines the various <br /> types of data we collect from you:</h1>

                <h1 className='mt-5'>Information You Provide to Us: <br />
                    - Personal identification information such as name, email address, phone number, etc., when you register for our <br /> Services. <br />
                    - Payment information, including credit card numbers and billing information, when you purchase our Services. <br />
                    - Feedback, ratings, and other content you provide when using our Services.
                </h1>

                <h1 className='mt-5'>
                    Information We Collect Automatically: <br />
                    - Usage data that includes information about how you use our website and Services. <br />
                    - Technical data such as IP address, your login data, browser type and version, time zone setting, and location, browser <br /> plug-in types and versions, operating system, and platform. <br />
                    - Cookies and similar tracking technologies track activity on our Service and store certain information.
                </h1>

                <h1 className='mt-5'>
                    Information We Obtain from Third Parties: <br />
                    - We may receive personal data about you from various third parties and public sources such as social media platforms, <br /> market research partners, and data brokers.
                </h1>

                <h1 className='mt-5'>How Data is Handled: <br />
                    - All personal data you provide to us is stored on secure servers. <br />
                    - We implement a variety of security measures to maintain the safety of your personal information when you enter, <br /> submit, or access your personal information. <br />
                    - We adhere to strict security guidelines, protecting your personal information against unauthorized access.</h1>
            </div>

            <div className='ml-72'>
                <h3 className='font-bold text-2xl mt-5'>Use of Information</h3>
                <h1>The data we collect serves a fundamental role in the enhancement and personalization of our Services. Below you'll find <br /> detailed information on how we utilize the information we collect:</h1>

                <h1 className=' mt-1'>
                    Analysis and R&D: <br />
                    - We use the collected data to analyze trends, administer the site, track users' movements around the site, and to gather <br /> demographic information about our user base as a whole. <br />
                    - Data is vital in our research and development efforts, allowing us to innovate and enhance the functionality and security <br /> of our Services.</h1>

                <h1 className='  mt-1'>
                    Improving Services: <br />
                    - We continually strive to improve our website offerings based on the information and feedback we receive from you. <br />
                    - Personal data helps us respond more effectively to your customer service requests and support needs.
                </h1>

                <h1 className=' mt-1'>
                    Marketing and Promotions: <br />
                    - The information you provide, whether public or private, will not be sold, exchanged, transferred, or given to any other <br /> company for any reason whatsoever, without your consent, other than for the express purpose of delivering the <br /> purchased product or service requested. <br />
                    - We may use the data to personalize ads and to show you promotions that are more relevant to your interests. <br />
                    - We may also send periodic emails that may include company news, updates, related product or service information, <br /> etc., using the email address which you have provided.
                </h1>
            </div>

            <div className='ml-72'>
                <h3 className='mt-10 font-bold text-2xl'>Data Ownership and Access</h3>
                <h1 className='mt-1'>Understanding the ownership and your rights over data is crucial. Here we define how data ownership is managed and <br /> what access you have to the data:</h1>

                <h1 className='mt-5'> Restaurant Data Ownership: <br />
                    - Restaurants retain ownership of the data they provide to 'The Genie' in the course of using our Services. This includes <br /> menu information, pricing, restaurant descriptions, and imagery.</h1>

                <h1 className='mt-5'>Shared Ownership: <br />
                    - While restaurants own their supplied data, 'The Genie' retains a shared right to use this data for analytical purposes, <br /> research and development, and promotional activities to improve service delivery and user experience. <br />
                    - Data generated as a result of restaurant and user interaction, including but not limited to transaction data, user <br /> feedback, and usage patterns, is also co-owned by 'The Genie'.</h1>


                <h1 className='mt-5'>User Rights to Access and Control: <br />
                    - You have the right to access personal data we hold about you and to ask that your personal data be corrected, <br /> updated, or deleted. <br />
                    - If you wish to exercise these rights, please contact us through the contact information provided in this policy. <br />
                    - We afford you the ability to keep your data accurate, complete, and up to date, and we will respond to your request to <br /> access, correct, or delete your personal data within a reasonable timeframe.</h1>
            </div>

            <div className='ml-72'>
                <h3 className='mt-10 font-bold text-2xl'>Data Sharing and Disclosure
                </h3>
                <h1>We recognize that your information is sensitive and an asset that requires careful stewardship. This section outlines the <br /> policies governing how we share and disclose data:
                </h1>

                <h1 className='mt-5'>Sharing with Service Providers and Partners: <br />
                    - We engage various service providers and partners to facilitate the operation of our Services, and we may share your <br /> personal data with them to the extent necessary for them to provide their services to us. <br />
                    - These partners include payment processors, cloud service providers, marketing partners, and others who assist us <br /> in delivering our Services.</h1>

                <h1 className='mt-5'>
                    Legal and Safety-Related Disclosures: <br />
                    - We may disclose your data when we believe in good faith that such disclosure is necessary to comply with the law, such <br /> as to comply with a subpoena or similar legal process. <br />
                    - When we believe that disclosure is necessary to protect our rights, protect your safety or the safety of others, <br /> investigate fraud, or respond to a government request.
                </h1>

                <h1>
                    Transfer of Business Assets: <br />
                    - In the event that 'The Genie' is involved in a merger, acquisition, or sale of all or a portion of its assets, your personal <br /> data may be transferred as part of that transaction. <br />
                    - We will notify you via email and/or a prominent notice on our website of any change in ownership or uses of your <br /> personal data, as well as any choices you may have regarding your personal data.
                </h1>
            </div>


            <div className='ml-72'>
                <h3 className='font-bold text-2xl'>Cookies and Tracking Technologies</h3>

                <h1 className='mt-2'>Our use of cookies and other tracking technologies is a standard practice for improving user experience and service <br /> delivery. Here's how we handle these technologies:
                </h1>

                <h1 className='mt-5'>Use of Cookies: <br />
                    - Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web <br /> browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and <br /> remember certain information. <br />
                    - We use cookies to help us remember and process the items in your shopping cart, understand and save your <br /> preferences for future visits, and compile aggregate data about site traffic and site interaction so that we can offer better <br /> site experiences and tools in the future.</h1>

                <h1 className='mt-5'>Tracking Technologies in ERP and AI Services: <br />
                    - We may also use various tracking technologies, including pixels, tags, and other analytics tools in our ERP and AI <br /> services to monitor the performance of our services, understand usage patterns, and improve user experience.</h1>

                <h1 className='mt-5'>User Options for Tracking: <br />
                    - You have the option to set your web browser to refuse cookies, or to alert you when cookies are being sent. If you <br /> choose to decline cookies, it may affect certain personalized features of our services. <br />
                    - Most web browsers also offer the choice to disable other tracking technologies. We provide you with the necessary <br /> information on how to manage these settings in the support section of our website. <br />
                    - For opting out of the use of cookies and tracking technologies by certain third-party service providers, you can manage <br /> your preferences through selective opt-out services provided by industry groups, or directly via the service provider’s <br /> websites.</h1>
            </div>

            <div className='ml-72'>
                <h2 className='font-bold text-2xl mt-10'>Data Security</h2>
                <h1 className='mt-2'>The protection of your data is a top priority at 'The Genie'. We have put in place appropriate security measures to <br /> prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. <br /> Here is an overview of our data security protocols:
                </h1>

                <h1 className='mt-5'>Security Measures: <br />
                    - We employ industry-standard security measures such as SSL encryption to protect data transmission. <br />
                    - Our network is protected and routinely monitored to prevent unauthorized access or breaches. <br />
                    - Access to personal data is strictly limited to employees, contractors, and agents who have a business need to know. <br /> They are subject to confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.</h1>

                <h1 className='mt-5'>Data Retention Policies: <br />
                    - We only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the <br /> purposes of satisfying any legal, accounting, or reporting requirements. <br />
                    - To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the <br /> personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for <br /> which we process your personal data and whether we can achieve those purposes through other means, and the <br /> applicable legal requirements.</h1>

                <h1 className='mt-5'>Data Breach Notification: <br />
                    - In the unlikely event of a data breach, we have put in place procedures to deal with any suspected personal data breach <br /> and will notify you and any applicable regulator of a breach where we are legally required to do so.
                </h1>

            </div>

            <div className='ml-72'>
                <h2 className='font-bold text-2xl mt-10'>User Rights and Preferences</h2>
                <h1>Your rights regarding your personal data are essential to us. Below you will find the rights you possess and the <br /> preferences you can set in terms of your data:</h1>

                <h1 className='mt-5'>Access, Correction, and Deletion: <br />
                    - You have the right to request access to the personal data we hold about you, to have any inaccuracies corrected, and to <br /> have your personal data deleted. <br />
                    - You can also request that we restrict the processing of your personal data in certain circumstances.</h1>

                <h1 className='mt-5'>
                    Data Portability: <br />
                    - Where we rely on your consent or the fulfillment of a contract as the legal basis for processing your personal data, you <br /> have the right to receive that data in a structured, commonly used and machine-readable format. <br />
                    - You also have the right to request that we transfer this data directly to another data controller, where technically <br /> feasible.
                </h1>

                <h1 className='mt-5'>
                    Opt-Out and Unsubscribe: <br />
                    - We provide you with the opportunity to 'opt-out' of having your personal data used for certain purposes, whenever we <br /> ask for this information. <br />
                    - If you no longer wish to receive promotional communications, you may opt-out of receiving them by following the <br /> instructions included in each communication.
                </h1>
            </div>

            <div className='ml-72'>
                <h2 className='font-bold text-2xl mt-10'>
                    International Data Transfers</h2>
                <h1 className='mt-1'>In the global digital economy, data may need to cross international borders. We handle such transfers with the utmost <br /> care and in compliance with applicable laws. Below are our practices for international data transfers:</h1>


                <h1 className='mt-5'>Cross-Border Data Transfer: <br />
                    - When we transfer your personal data out of your jurisdiction, we ensure a similar degree of protection is afforded to it <br /> by ensuring at least one of the following safeguards is implemented: <br />
                    - We will only transfer your personal data to countries that have been deemed to provide an adequate level of <br /> protection for personal data. <br />
                    - Where we use certain service providers, we may use specific contracts approved for use in your jurisdiction which give <br /> personal data the same protection it has in your jurisdiction.  </h1>


                <h1 className='mt-5'>
                    Adherence to International Privacy Regulations: <br />
                    - We comply with applicable legal frameworks relating to the transfer of data, such as the GDPR for transfers from the <br /> European Union.
                    - Our commitment to data protection means upholding the principles of privacy and data protection, regardless of where <br /> your data is processed or stored.
                </h1>


            </div>



            <div className='ml-72'>
                <h2 className='font-bold text-2xl mt-10'>Changes to the Privacy Policy</h2>

                <h1 className='mt-1'>We may update our privacy policy from time to time. We encourage you to review this policy periodically for any <br /> changes:</h1>

                <h1 className='mt-5'>Notification of Changes: <br />
                    - Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, <br /> through a notice on our website. <br />
                    - We will also update the 'effective date' at the top of this privacy policy.</h1>

                <h1 className='mt-5'>Effective Date of New Policies: <br />
                    - The changes to our privacy policy will become effective upon posting of the revised policy on the website. Your <br /> continued use of our Services following the posting of changes will mean that you accept and agree to the changes.</h1>
            </div>


            <div className='ml-72'>

                <h2 className='font-bold text-2xl mt-10'>Contact Information</h2>

                <h1 className='mt-2'>Should you have any privacy-related concerns or issues, you're encouraged to reach out directly to our grievance officer <br /> for prompt assistance. In accordance with Information Technology Act, 2000 and rules made thereunder, for any general <br /> inquiries or data practice questions, please contact us using the details below:
                </h1>


                <h1 className='mt-10'>
                    Contact Details for Privacy Concerns:</h1>

                <h1 className='mt-5'>
                    Grievance Officer: <br />
                    Name: Koushik Raj <br />
                    Generic Technologies Private Limited <br />
                    Address: #32, Arcot Road, Virugambakkam, <br />
                    Chennai, India. <br />
                </h1>
                <p>
                    <span onClick={handleMailClick} style={{ color: 'black', cursor: 'pointer' }}>
                        {mailId}
                    </span>
                </p>
                <h1>Time: 10 AM – 5 PM (Monday – Friday)
                </h1>

                <h1 className='mt-5'>Please reach out to our grievance officer directly for any concerns or issues related to privacy.</h1>
            </div>


            <div className='ml-72'>
                <h2 className='font-bold text-2xl mt-10'>Governing Law and Jurisdiction</h2>

                <h1 className='mt-1'>The laws of India will govern this Privacy Policy as well as any dispute that might arise between you and us, without regard to conflict of law provisions. We both agree to the exclusive jurisdiction and venue of the courts located in India, for any legal actions related to this Privacy Policy.</h1>

                <h1 className='mt-8 mb-5'>This declaration affirms that our operations adhere to the laws of India and that any disputes will be handled within the jurisdiction of the Indian legal system. It is crucial for users to understand the legal framework governing the use of our Services and the management of their personal data.</h1>
            </div>

            <footer className="bg-pink-100 py-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-2 sm:grid-cols-4 gap-8">
                    <div>
                        {/* <h3 className="text-xl font-bold text-red-600">Fooodish</h3> */}
                        <img src={logo} alt="" />
                        <p className="mt-2 text-gray-600">Continue Genie 2025 all rights reserved</p>
                        <h4 className="mt-6 text-lg font-bold text-red-600">Follow Us On</h4>
                        <div className="flex space-x-4 mt-4 justify-center cursor-pointer">
                            <FaPinterestP className="text-gray-600 text-2xl hover:text-green" />
                            <FaInstagram className="text-gray-600 text-2xl hover:text-green" />
                            <FaTwitter className="text-gray-600 text-2xl hover:text-green" />
                            <FaFacebookF className="text-gray-600 text-2xl hover:text-green" />
                        </div>
                    </div>
                    <div className='text-start'>
                        <h3 className="text-xl font-bold text-green ml-28">Menu</h3>
                        <ul className="mt-2 space-y-2 cursor-pointer ml-28">
                            <li className="text-gray-600">Home</li>
                            <li className="text-gray-600">Offers</li>
                            <li className="text-gray-600">Service</li>
                            <li className="text-gray-600">Restaurants</li>
                        </ul>
                    </div>
                    <div className='text-start'>
                        <h3 className="text-xl font-bold text-green">Information</h3>
                        <ul className="mt-2 space-y-2">
                            <a href=""> <li className="text-gray-600">Privacy Policy</li></a>
                            {/* <Link to="/privacy">Privacy Policy</Link> */}
                            <a href=""> <li className="text-gray-600">Terms & Conditions</li></a>

                            <li className="text-gray-600">Refund & Cancellation</li>
                            <li className="text-gray-600">Investors</li>
                            <li className="text-gray-600">About Us</li>
                            <li className="text-gray-600">Partner With Us</li>
                        </ul>
                    </div>
                    <div className='text-start'>
                        <h3 className="text-xl font-bold text-green">Contact</h3>
                        <ul className="mt-2 space-y-2">
                            <li className="text-gray-600"> +91 8667211896</li>
                            <li className="text-gray-600">Get In Touch With Us</li>
                            <li className="text-gray-600">getintouch@thegenie.in</li>
                            <li className="text-gray-600">GENERIC TECHNOLOGIES PRIVATE LIMITED <br />

                                No.32, Arcot Road,

                                Virugambakkam,

                                Chennai - 600092.

                                India</li>
                        </ul>
                    </div>
                </div>
            </footer>

        </>
    );
};

export default Privacy;
