
import React, { useState, useEffect } from 'react';
import { FaLeaf, FaBox, FaInfoCircle } from 'react-icons/fa';
import { MdOutlineAccessTime } from 'react-icons/md';
import logo from "../assets/logo1.webp";
// import { Link } from 'react-router-dom';

import { FaPinterestP, FaInstagram, FaTwitter, FaFacebookF } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import wadhawani from '../assets/wadhawani.png';
import startupindia from '../assets/startup-removebg-preview.png';
import awsamazon from '../assets/amazonaws.png';
import razorpay from '../assets/razorpay-removebg-preview.png';
import microsoft from '../assets/microsoft-removebg-preview.png';
import '../style/support.css';


function Terms() {
    //header functionality
    const [menuOpen, setMenuOpen] = useState(false); // State to toggle mobile menu
    const [activeMenu, setActiveMenu] = useState(''); // State to track active menu

    // Menu items
    const menu = [
        { title: "Menu", link: "/menu", icon: <FaLeaf /> },
        { title: "Offers", link: "/offers", icon: <FaBox /> },
        { title: "Services", link: "/services", icon: <MdOutlineAccessTime /> },
        { title: "Restaurants", link: "/restaurants", icon: <FaInfoCircle /> },
    ];

    // Set active menu based on current URL
    useEffect(() => {
        const currentPath = window.location.pathname;
        const active = menu.find((item) => item.link === currentPath);
        if (active) {
            setActiveMenu(active.title);
        }
    }, []);

    // Handle menu click
    const handleMenuClick = (title) => {
        setActiveMenu(title); // Set active menu
        setMenuOpen(false); // Close mobile menu after clicking
    };

    return (

        <>
            < header className="bg-light-grey text-black p-4 fixed w-full top-0 left-0 z-10 shadow-sm" >
                <div className="container mx-auto flex justify-between items-center">
                    {/* Logo */}
                    <div className="text-2xl font-bold">
                        <img src={logo} alt="logo" className="h-16 px-10 w-auto" />
                    </div>

                    {/* Desktop Navigation */}
                    <nav className="hidden md:flex space-x-16">
                        {menu.map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className={`relative hover:text-green font-bold transition-all duration-300 ${activeMenu === item.title ? 'text-green' : ''
                                    }`}
                                onClick={() => handleMenuClick(item.title)}
                            >
                                {item.title}
                                {/* Underline effect for active menu */}
                                <span
                                    className={`absolute left-0 -bottom-1 h-0.5 bg-green transition-all duration-300 ${activeMenu === item.title ? 'w-full' : 'w-0'
                                        }`}
                                ></span>
                            </a>
                        ))}
                    </nav>

                    {/* Buttons */}
                    <div className="flex items-center space-x-4">
                        <div className='mt-2'>
                            <button className='bg-white text-black border-2 border-green w-40 h-8 rounded font-semibold hover:bg-green hover:text-white text-center'>
                                Download Our App
                            </button>
                        </div>
                        <div className='mt-2'>
                            <a href="https://onboarding.thegenie.in/" target="_blank" rel="noopener noreferrer">
                                <button className='bg-green text-white w-40 h-8 rounded font-semibold hover:bg-white hover:text-black hover:border-2 hover:border-green text-center'>
                                    Partner With Us
                                </button>
                            </a>
                        </div>
                    </div>

                    {/* Hamburger Menu for Mobile */}
                    <button
                        className="md:hidden text-black"
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>

                {/* Mobile Navigation */}
                {
                    menuOpen && (
                        <nav className="md:hidden bg-light-grey mt-4">
                            {menu.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.link}
                                    className={`block py-2 px-4 hover:text-green font-bold ${activeMenu === item.title ? 'text-green' : ''
                                        }`}
                                    onClick={() => handleMenuClick(item.title)}
                                >
                                    {item.title}
                                </a>
                            ))}
                        </nav>
                    )
                }
            </header >

            <div>
                <div className='ml-72'>
                    <div className='mt-36'>
                        <h6 className='font-bold justify-center text-5xl'>Terms and Conditions of Service </h6>
                        <h4 className='font-bold mt-2 text-2xl'>The Genie | Generic Technologies Private Limited</h4>
                        <h1 className='font-semibold mt-2'>Effective from 6th November 2023.</h1>
                    </div>


                    <div>
                        <h3 className='font-semibold text-2xl mt-20'>Introduction</h3>
                        <h1 className='mt-1'>This document constitutes the Terms and Conditions ("Terms") governing your use of the services provided by The <br /> Genie ("we", "us", "our", or "The Genie"), a comprehensive platform offering food takeout and AI-powered <br /> Enterprise Resource Planning (ERP) services. By accessing or using any part of our services, you agree to be bound <br /> by these Terms, which are designed to ensure a clear understanding of mutual rights and obligations. Your <br /> agreement with us includes these Terms and any additional terms that you agree to, as provided in connection with <br /> specific services, subject to the terms herein.</h1>

                        <h3 className='font-semibold text-2xl mt-10'>
                            Definition of Terms
                        </h3>
                        <h1 className='mt-1'>For the purposes of these Terms, the following definitions shall apply:</h1>

                        <h1 className='mt-5'>- "Services" refers to all the services provided by The Genie, including but not limited to food takeout services, AI- <br />powered operational analytics, ERP services, and any related features, technologies, and functionalities offered <br />     through our platform.
                        </h1>

                        <h1 className='mt-5'>- "Users" denotes individuals or entities that access or use our Services for the purpose of food ordering, data <br /> analysis, or any other use case facilitated by our platform.</h1>

                        <h1 className='mt-5'>- "Partners" encompasses all restaurants, vendors, third-party service providers, and any other entities that <br /> collaborate with The Genie to offer products, services, or functionalities through our platform.</h1>


                        <h1 className='mt-5'> - "ERP Services" pertains to the AI-powered solutions provided by The Genie, designed to assist in the <br /> management of a restaurant's operations, including but not limited to analytics, reporting, inventory management, <br /> and data-driven decision-making tools.</h1>

                        <h1 className='mt-5'>
                            - "Takeout Services" refers to the service that allows Users to order food for pickup or takeout from various Partner <br /> restaurants through The Genie's platform.</h1>


                        <h1 className='mt-5'>

                            - "Data Ownership" means the rights and interests that Partners retain over the data generated from their <br /> operations and interactions with The Genie's Services.
                        </h1>



                    </div>


                    <div>
                        <h1 className='font-semibold text-2xl mt-10'>
                            User Account and Access</h1>
                        <h2 className='font-semibold text-xl mt-5'>
                            User Account Creation</h2>
                        <h3>To utilize the full spectrum of The Genie’s Services, users may be required to create an account. When creating an <br /> account, users agree to provide current, complete, and accurate purchase and account information for all purchases <br /> made via the Service. Users agree to promptly update account and other information, including email address and <br /> credit card numbers and expiration dates, so that we can complete transactions and contact you as needed. <br /> Registration must be done by a human. Accounts registered by 'bots' or other automated methods are not <br /> permitted and will be deleted without notice. Each user is responsible for whatever happens on their account and <br /> must report any unauthorized accesses in a timely manner.</h3>

                        <h2 className='font-semibold text-xl'>Account Responsibilities</h2>
                        <h6>As a registered user, you are responsible for safeguarding your account details, including passwords and other <br /> sensitive information. You are solely responsible for all activities that occur under your account and agree to notify <br />    The Genie immediately of any unauthorized use of your account. The Genie cannot and will not be liable for any loss <br /> or damage arising from your failure to comply with the above requirements. You may be held liable for any losses <br /> incurred by The Genie or any other user of or visitor to the Site due to someone else using your Genie ID, password, <br /> or account.</h6>
                    </div>


                    <div>
                        <h2 className='font-semibold text-2xl mt-5'>Modification of Terms</h2>

                        <h2 className='font-semibold text-xl mt-5'>
                            Right to Modify Terms</h2>
                        <h6>The Genie reserves the right, at our sole discretion, to change, modify, add or remove portions of these Terms at <br /> any time. Changes will be effective when posted on the Service with no other notices provided and you are deemed <br /> to be aware of and bound by any changes to the foregoing upon their publication on our Service. It is your <br /> responsibility to check these Terms periodically for changes. Continued use of the Service following the posting of  <br /> changes will mean that you accept and agree to the changes. Assuming that any change is unacceptable to you, you <br /> shall cease using this Service.</h6>

                        <h2 className='font-semibold text-xl mt-5'>
                            Acceptance of Modified Terms</h2>

                        <h6>Your continued engagement with The Genie’s Services following any amendments indicates that you accept the <br /> modified Terms. You acknowledge and agree that by logging into your account, or using our Services after changes <br /> to these Terms have been posted, you are expressing your acceptance of the updated or modified Terms. If you do <br /> not agree to any change to these Terms then you must stop using the Services immediately.</h6>
                    </div>

                    <div>
                        <h2 className='font-semibold text-2xl mt-5'>

                            Services Description</h2>

                        <h2 className='font-semibold text-xl mt-5'>The Genie's Service Overview</h2>
                        <h6>The Genie offers an amalgamation of services designed to cater to the evolving needs of the food service industry <br /> and its patrons. Our platform provides a seamless interface for ordering takeout from a wide array of partner <br /> restaurants, alongside robust AI-powered ERP solutions tailored for restaurant management. The Genie's ERP <br /> services empower restaurants with operational analytics, offering insights into performance, customer behavior, and <br /> market trends, thereby enabling data-driven decision-making.</h6>

                    </div>

                    <div>
                        <h1 className='font-semibold text-xl mt-5'>
                            AI-Powered Operational Analytics
                        </h1>

                        <h6>
                            By leveraging cutting-edge AI technology, The Genie delivers advanced analytical tools that process operational <br /> data to unveil actionable insights. These analytics cover various aspects of restaurant management, including sales <br /> trends, inventory levels, staffing efficiency, and customer satisfaction metrics.
                        </h6>

                        <h1 className='font-semibold text-xl mt-5'>
                            Takeout Services
                        </h1>
                        <h6>The Genie’s takeout services provide a convenient option for users to order food online from our extensive network <br /> of partner restaurants. Our platform is designed to facilitate an intuitive ordering process, ensuring a satisfactory <br /> experience from menu browsing to order placement and pickup.</h6>
                    </div>


                    <div>
                        <h1 className='font-semibold text-2xl mt-5'>User Obligations</h1>

                        <h2>Compliance with Terms and Local Laws
                        </h2>

                        <h6>As a user of The Genie, you agree to comply with these Terms and all local, state, national, and international laws, <br /> statutes, ordinances, and regulations that apply to your use of the Services. It is your responsibility to ensure that <br /> your use of The Genie’s Services does not contravene any applicable laws.</h6>

                        <h1 className='font-semibold text-xl'>Accuracy of Information</h1>

                        <h6>You pledge to provide authentic and current information required for the use of The Genie’s Services and to <br /> maintain the accuracy of such information. The Genie relies on this information to provide you with its Services, and <br /> any misleading, false, or outdated information may lead to suspension or termination of your access to the Services.</h6>

                        <h1 className='font-semibold text-xl'>Responsible Conduct</h1>

                        <h6>You commit to engaging with The Genie’s Services in a manner that is respectful and considerate of other users and <br /> our partner restaurants. Any conduct by you that, in The Genie’s discretion, restricts or inhibits any other person or <br /> entity from using or enjoying any of The Genie’s Services is strictly prohibited and may result in the termination <br /> of your access to the Services.

                        </h6>
                    </div>

                    <div>
                        <h2 className='font-semibold text-2xl mt-10'>
                            Intellectual Property Rights

                        </h2>

                        <h1 className='font-semibold text-xl mt-5'>
                            Ownership and Use of Content</h1>

                        <h6>The Genie and its associated logos and marks are proprietary trademarks of The Genie, including the design, <br /> graphics, and text of our Services. The Genie owns the intellectual property rights in the content and materials <br /> displayed on the platform. By using the Services, you agree not to copy, distribute, modify, or make derivative works <br /> of any materials without the prior written consent of The Genie.</h6>
                    </div>

                    <div>
                        <h2 className='font-semibold text-xl'>User-Generated Content</h2>

                        <h6>When you submit content to The Genie, you grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty <br />-free, sublicensable license to use, reproduce, adapt, publish, translate, create derivative works from, distribute, <br /> perform, and display such content in connection with the provision of the Services.

                        </h6>

                        <h2 className='font-semibold text-xl'>Protection of Intellectual Property</h2>

                        <h6>The Genie respects the intellectual property of others and expects the same from its Users. If you believe that your <br /> intellectual property rights have been infringed, please inform us, and we will take appropriate action in accordance <br /> with our IP policy and applicable laws.</h6>
                    </div>

                    <div>
                        <h2 className='font-semibold text-2xl'>ERP Services Specific Terms</h2>

                        <h2 className='font-semibold text-xl'>Scope of ERP Services</h2>

                        <h6>The Genie's ERP services provide an integrated suite of tools to optimize restaurant operations. These services <br /> include, but are not limited to, inventory management, financial reporting, staff scheduling, and predictive analytics. <br /> The Genie grants you a limited, non-exclusive, non-transferable license to use the ERP Services for your internal <br /> business purposes in accordance with these Terms.</h6>

                        <h2 className='font-semibold text-xl'>Data Handling and Ownership</h2>

                        <h6>Restaurants retain ownership of the data they generate while using The Genie’s ERP services. The Genie may use <br /> this data to provide and improve the Services, subject to the terms of our Privacy Policy. The Genie ensures that all  <br />data is handled securely and in compliance with relevant data protection regulations.</h6>

                        <h2 className='font-semibold text-xl'>Third-Party Integrations</h2>

                        <h6>The ERP Services may integrate with third-party services. While The Genie strives to offer seamless integrations, we <br /> are not responsible for the performance of third-party services or their compliance with applicable laws. You agree <br /> that The Genie shall not be liable for any issues arising from the use of any third-party services.</h6>
                    </div>


                    <div>
                        <h2 className='font-semibold text-xl mt-5'>Takeout Services Specific Terms</h2>

                        <h1 className='font-semibold text-xl mt-10'>Overview of Takeout Services</h1>

                        <h6>The Genie’s takeout services facilitate an efficient ordering process for users seeking to enjoy meals from our <br /> network of partner restaurants. The services are designed to be user-friendly, providing clear menus, accurate <br /> pricing, and real-time updates on order status.</h6>

                        <h2 className='font-semibold text-xl'>Order Placement and Processing</h2>

                        <h6>When placing an order through The Genie’s platform, you are making a direct offer to purchase the product from <br /> the selected restaurant. Upon receiving your order, we will send an acknowledgment email confirming receipt. This <br /> confirmation does not constitute acceptance of your order; the contract between you and the restaurant is only <br /> formed when we send you confirmation via email that the restaurant has accepted your order.</h6>
                    </div>

                    <div>
                        <h2 className='font-semibold text-2
                    xl'>Payment and Pricing</h2>

                        <h6>All prices listed on The Genie’s platform are determined by the partner restaurants. The Genie takes no <br /> responsibility for the pricing of food items. Payment for all orders must be made in full at the time of ordering via <br /> our secure payment mechanisms.</h6>
                    </div>

                    <div >
                        <h2 className='font-semibold text-xl'> Commitment to Data Protection</h2>
                        <h2>The Genie is committed to protecting the privacy and security of our users' and partners' data. Our Privacy Policy <br /> outlines the types of data we collect, how we use it, and the measures we take to ensure your personal information <br /> is handled with care and in compliance with applicable laws and regulations.</h2>

                        <h2 className='font-semibold text-xl'>User Data</h2>
                        <h6>In delivering our Service, we may collect personal data such as your name, contact details, and payment information. <br /> This data is essential for providing you with our Services and is handled in accordance with our Privacy Policy.</h6>

                        <h2 className='font-semibold text-xl'>Partner Data</h2>

                        <h6>Partner restaurants retain ownership of the data they generate while using our Services. We ensure that partners <br /> have access to analytics tools and that their data remains secure and confidential.</h6>

                        <h2 className='font-semibold text-xl'>Data Security</h2>

                        <h6>We employ appropriate security measures to protect your information against unauthorized access, alteration, <br /> disclosure, or destruction. We continuously review and update our security practices to enhance the safety of your <br /> personal information.</h6>
                    </div>

                    <div >


                        <h2 className='font-semibold text-2xl mt-5'>
                            Restrictions on Use
                        </h2>
                        <h6>Prohibited Activities: <br />
                            The Genie's services are provided to help users order takeout efficiently and to enable restaurants to manage their <br /> operations effectively. To maintain the integrity of our services, the following activities are expressly prohibited: <br />
                            - Any form of unauthorized automated use of the system, including the use of scripts to send comments <br /> or messages. <br />
                            - Interfering with, disrupting, or creating an undue burden on the Services or the networks or services connected to <br /> the Services. <br />
                            - Attempting to impersonate another user or person. <br />
                            - Using the account, username, or password of another user at any time or disclosing your password to any third <br /> party or permitting any third party to access your account. <br />
                            - Selling or otherwise transferring your profile. <br />
                            - Using any information obtained from the Services to harass, abuse, or harm another person. <br />
                            - Using the Services in a manner inconsistent with any applicable laws and regulations. <br />



                            Intellectual Property Violations: <br />
                            We take intellectual property rights seriously and require that our users do the same. Users must not upload, post, <br /> or otherwise distribute or facilitate the distribution of any content that: <br />
                            - Infringes on any trademark, patent, trade secret, copyright, or other proprietary rights of any party. <br />
                            - Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's <br /> privacy, tortious, or contains explicit or graphic descriptions or accounts of sexual acts.</h6>
                    </div>


                    <div>
                        <h2 className='font-semibold text-2xl mt-5'>User Feedback and Ratings</h2>

                        <h6 className='font-semibold text-xl mt-10'>Feedback Submission</h6>

                        <h6>Users of The Genie's platform may submit feedback and ratings for the services and restaurants they have interacted <br /> with. This feedback is crucial for maintaining the quality of our services and fostering a trustworthy community.</h6>

                        <h2 className='font-semibold text-xl'>Integrity of Feedback</h2>

                        <h6>
                            To ensure the integrity and accuracy of feedback: <br />
                            - Users must provide honest comments that represent their genuine experience. <br />
                            - Any attempt to manipulate ratings or feedback by creating false accounts, coercing other users, or any other <br /> deceptive or fraudulent activity is strictly prohibited. <br />
                            - The Genie reserves the right to remove feedback that we determine, in our sole discretion, to be fraudulent, <br /> abusive, or in violation of our terms and conditions.
                        </h6>
                    </div>


                    <div>

                        <h2 className='font-semibold text-2xl mt-5'>Advertising and Promotions</h2>

                        <h2 className='font-semibold text-xl mt-10'>Advertisements on The Genie</h2>

                        <h6>The Genie may display advertisements and promotions from third parties on its platform. The manner, mode, and <br /> extent of advertising by The Genie are subject to change, and the appearance of advertisements on the platform <br /> does not necessarily imply an endorsement or recommendation by The Genie.</h6>

                        <h2 className='font-semibold text-xl'>User Participation in Promotions</h2>


                        <h6>From time to time, The Genie or its partner restaurants may conduct promotions that users can participate in. These <br /> promotions, including contests, sweepstakes, and other such events, may be governed by rules that are separate  <br />from these Terms. If you participate in any promotions, please review the applicable rules as well as our Privacy <br /> Policy. Participation in these promotions is completely voluntary, and you have a choice whether or not to disclose <br /> any information that may be required.</h6>
                    </div>


                    <div >
                        <h2 className='font-semibold text-2xl'>Additional Legal Terms</h2>

                        <h2 className='font-semibold text-xl'>Warranties and Disclaimers</h2>

                        <h6>The Genie provides its services using a commercially reasonable level of skill and care, but there are certain things <br /> that we don't promise about our services. Other than as expressly set out in these terms or additional terms, neither <br /> The Genie nor its suppliers or distributors make any specific promises about the services. For example, we don’t <br /> make any commitments about the content within the services, the specific functions of the services, or their <br /> reliability, availability, or ability to meet your needs. We provide the services 'as is'.</h6>

                        <h2 className='font-semibold text-xl'>Limitation of Liability
                        </h2>

                        <h6>To the extent permitted by law, The Genie, its affiliates, officers, employees, agents, suppliers, or licensors will not <br /> be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, <br /> whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) <br /> your access to or use of or inability to access or use the services; (b) any conduct or content of any third party on the <br /> services; (c) any content obtained from the services; or (d) unauthorized access, use, or alteration of your transmissions or content.</h6>
                    </div>


                    <div>
                        <h2 className='font-semibold text-2xl mt-5'>Termination and Suspension</h2>

                        <h2 className='font-semibold text-2xl mt-10'>Grounds for Termination</h2>

                        <h6>The Genie reserves the right to terminate or suspend your account and access to the Services immediately, without <br /> prior notice or liability, under circumstances including, but not limited to, your breach of the Terms, unauthorized <br /> use of our intellectual property, or actions that cause harm to The Genie or its users. Upon termination, your right to <br /> use the Services will cease immediately.
                        </h6>

                        <h6 className='font-semibold text-xl'>Effect of Termination</h6>

                        <h6>In the event of termination, you will lose all access to any services for which you have registered. All provisions of the <br /> Terms which, by their nature, should survive termination, shall survive termination, including, without limitation, <br /> ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</h6>

                        <h6 className='font-semibold text-xl'>Suspension of Services</h6>

                        <h6>We may suspend your user account or temporarily disable access to whole or part of any Service in the event of any <br /> suspected illegal activity, extended periods of inactivity, or requests by law enforcement or other government <br /> agencies.</h6>
                    </div>

                    <div >

                        <h1 className='font-semibold text-2xl mt-5'>Dispute Resolution</h1>

                        <h2 className='font-semibold text-xl mt-10'>
                            Governing Law</h2>

                        <h6>These Terms will be governed by and construed in accordance with the laws of India, without giving effect to any <br /> principles of conflicts of law.</h6>

                        <h2 className='font-semibold text-xl'>Arbitration Agreement</h2>

                        <h6>In the unlikely event that The Genie has not been able to resolve a dispute it has with you after attempting to do so <br /> informally, we each agree to resolve any claim, dispute, or controversy (excluding any The Genie claims for injunctive <br />or other equitable relief) arising out of or in connection with or relating to these Terms, or the breach or alleged <br /> breach thereof (collectively, "Claims"), by binding arbitration by the Indian Arbitration and Conciliation Act, 1996.</h6>
                        <h2 className='font-semibold text-xl'>Class Action Waiver</h2>

                        <h6>Any claims must be brought in the parties' individual capacity, and not as a plaintiff or class member in any purported <br /> class or representative proceeding. You agree that, by entering into these terms, you and The Genie are each waiving <br /> the right to a trial by jury or to participate in a class action.</h6>
                    </div>

                    <div >
                        <h1 className='font-semibold text-2xl mt-10'>General Provisions</h1>

                        <h2 className='font-semibold text-xl mt-5'>
                            Entire Agreement</h2>

                        <h6>These Terms constitute the entire agreement between you and The Genie and govern your use of the Service, <br /> superseding any prior agreements between you and The Genie (including, but not limited to, any prior versions of <br /> the Terms).</h6>

                        <h2 className='font-semibold text-xl'>No Waiver</h2>

                        <h6>The failure of The Genie to exercise or enforce any right or provision of these Terms shall not constitute a waiver of <br /> such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, <br /> the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the <br /> provision, and the other provisions of the Terms remain in full force and effect.</h6>

                        <h2 className='font-semibold text-xl'>Assignment</h2>

                        <h6>You may not assign or transfer these Terms, by operation of law or otherwise, without The Genie’s prior written <br /> consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null and of no effect. <br /> The Genie may assign or transfer these Terms, at its sole discretion, without restriction.</h6>



                    </div>

                    <div>
                        <h2 className='font-semibold text-2xl mt-10'>Notices and Amendments</h2>
                        <h1 className='font-semibold text-xl mt-5'>Method of Providing Notices</h1>

                        <h6>The Genie may provide you with notices, including those regarding changes to the Terms, by email, <br /> regular mail, text message, postings on our services, or other reasonable means now known or hereafter developed.</h6>


                        <h2 className='font-semibold text-xl'>Procedure for Amendments</h2>

                        <h6>The Genie reserves the right to update or modify these Terms at any time without prior notice, and such changes <br /> will be effective immediately upon being posted on the site or service. It is your responsibility to review these Terms <br /> periodically for changes. Your continued use of the Service following the posting of changes will mean that you <br /> accept and agree to the changes.</h6>

                    </div>

                    <div>
                        <h2 className='font-semibold text-2xl'>Contact Information</h2>

                        <h1>Customer Service Contact</h1>

                        <h6>For questions or concerns regarding the Service, your account, or these Terms, you may contact The Genie's <br /> Customer Service at the following details:</h6>

                        <h6 className='mt-5'>
                            Email: getintouch@thegenie.in <br />
                            Phone: +91 8667211896 <br />
                            Postal Address: No.32, Arcot Road, <br />
                            Virugambakkam, <br />
                            Chennai - 600092. <br />
                            India <br />
                        </h6>

                        <h2 className='font-semibold text-xl mt-5'>Grievance Officer</h2>

                        <h6>In accordance with the Information Technology Act 2000 and the rules made thereunder, the contact details of the <br /> Grievance Officer are provided below:</h6>

                        <h6 className='mt-5'>Grievance Officer: <br />
                            Name: Koushik Raj <br />
                            Generic Technologies Private Limited <br />
                            Address: #32, Arcot Road, Virugambakkam, <br />
                            Chennai, India. <br />
                            Email: koushikraj@generictechltd.com <br />
                            Time: 10 AM – 5 PM (Monday – Friday) <br />
                        </h6>

                        <h6>Please reach out to our grievance officer directly for any concerns or issues related to privacy. The Grievance Officer <br /> is responsible for redressing the grievances of users regarding content and service.

                        </h6>

                        <h2 className='font-semibold text-xl mt-5'>Nodal Contact Person</h2>

                        <h6>For any law enforcement related queries, please contact our Nodal Officer at the following details:</h6>

                        <h6 className='mt-5'>
                            Name: [Name] <br />
                            Generic Technologies Private Limited <br />
                            Address: #32, Arcot Road, Virugambakkam, <br />
                            Chennai, India. <br />
                            Email: info@generictechltd.com <br />
                            Time: 10 AM – 5 PM (Monday – Friday) <br />
                        </h6>
                    </div>


                    <div>
                        <h2 className='font-semibold text-2xl mt-5'  >Copyright Infringement and DMCA Policy</h2>

                        <h2 className='mt-5 font-semibold text-xl'>Notification Procedures
                        </h2>

                        <h6>The Genie respects the intellectual property of others and expects its users to do the same. In accordance with <br /> the Indian Copyright Act, 1957, The Genie has adopted a policy of terminating, in appropriate circumstances and at <br /> The Genie's sole discretion, users who are deemed to be repeat infringers. The Genie may also limit access to the Service <br /> and/or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any <br /> repeat infringement.</h6>

                        <h2 className='mt-5 font-semibold text-xl'>Counter-Notification Procedures
                        </h2>

                        <h6>If you believe that your content has been removed in error or misidentified, you may send us a counter-notice with the following information: <br />
                            - Your name, address, phone number, and physical or electronic signature;
                            - Identification of the material and its location before it was removed; <br />
                            - A statement under penalty of perjury that the material was removed by mistake or misidentification; <br />
                            - Your consent to local jurisdiction or agreement to accept service of process from the party who provided the notification of the alleged infringement.

                        </h6>
                    </div>

                    <div>
                        <h1 className='mt-5 font-semibold text-2xl'>Special Acknowledgments</h1>
                        <h2 className='mt-10 font-semibold text-xl'>AI Usage and Limitations</h2>

                        <h6>The Genie employs artificial intelligence (AI) to enhance service delivery, user experience, and operational efficiency. <br /> Users acknowledge that while AI can provide significant benefits, it is subject to limitations and should not be relied upon <br /> exclusively for decision-making. The Genie is not liable for any issues arising from AI operations, including data inaccuracies or service interruptions. <br /> The Genie disclaims any liability for errors or omissions by AI-operated services.</h6>

                        <h1 className='mt-5 font-semibold text-xl'>Restaurant Partnerships and Data Use</h1>

                        <h2>The Genie values its partnerships with various restaurants and food service providers. Users acknowledge that by <br /> using The Genie’s platform, they consent to the sharing of transactional data with our restaurant partners for the <br /> purpose of fulfilling orders and enhancing service delivery. All data shared will be in compliance with our <br /> Privacy Policy and relevant data protection laws.</h2>
                    </div>


                    <div>
                        <h2 className='mt-5 font-semibold text-2xl'>Conclusion</h2>

                        <h1 className='mt-5 font-semibold text-xl'>Summary of Agreement</h1>

                        <h6>This document and any documents referenced herein represent the entire agreement between The Genie and the user <br /> regarding the use of The Genie’s services. By using the Services, you acknowledge that you have read these <br />  Terms in their entirety, understand them, and agree to be bound by their terms and conditions. You further agree that these Terms are <br /> the complete and exclusive statement of agreement between you and The Genie, which supersedes any proposal or prior agreement, <br /> oral or written, and any other communications between you and The Genie relating to the subject matter of these Terms.</h6>

                        <h1 className='mt-5 font-semibold text-xl'>Affirmation of Understanding</h1>

                        <h6 >By using the services provided by The Genie, you affirm that you are fully able and competent to enter into the terms, conditions, <br /> obligations, affirmations, representations, and warranties set forth in these Terms and to abide by and comply with these Terms.</h6>

                    </div>




                </div>


                <div className='bg-light-grey mt-3'>
                    <h1 className='mt-10  text-2xl font-serif mb-5'>Supported By</h1>
                    <div className="marquee-container">
                        <div className="marquee-content">
                            <img src={startupindia} alt="Image 1" className="marquee-image" />
                            <img src={awsamazon} alt="Image 2" className="marquee-image" />
                            <img src={razorpay} alt="Image 3" className="marquee-image" />
                            <img src={microsoft} alt="Image 4" className="marquee-image" />
                            <img src={wadhawani} alt="Image 5" className="marquee-image" />
                        </div>
                    </div>
                </div>


                <footer className="bg-pink-100 py-10">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-2 sm:grid-cols-4 gap-8">
                        <div>
                            {/* <h3 className="text-xl font-bold text-red-600">Fooodish</h3> */}
                            <img src={logo} alt="" />
                            <p className="mt-2 text-gray-600">Continue Genie 2025 all rights reserved</p>
                            <h4 className="mt-6 text-lg font-bold text-red-600">Follow Us On</h4>
                            <div className="flex space-x-4 mt-4 justify-center cursor-pointer">
                                <FaPinterestP className="text-gray-600 text-2xl hover:text-green" />
                                <FaInstagram className="text-gray-600 text-2xl hover:text-green" />
                                <FaTwitter className="text-gray-600 text-2xl hover:text-green" />
                                <FaFacebookF className="text-gray-600 text-2xl hover:text-green" />
                            </div>
                        </div>
                        <div className='text-start'>
                            <h3 className="text-xl font-bold text-green ml-28">Menu</h3>
                            <ul className="mt-2 space-y-2 cursor-pointer ml-28">
                                <li className="text-gray-600">Home</li>
                                <li className="text-gray-600">Offers</li>
                                <li className="text-gray-600">Service</li>
                                <li className="text-gray-600">Restaurants</li>
                            </ul>
                        </div>
                        <div className='text-start'>
                            <h3 className="text-xl font-bold text-green">Information</h3>
                            <ul className="mt-2 space-y-2">
                                {/* <a href=""> <li className="text-gray-600">Privacy Policy</li></a> */}
                                <Link to="/privacy" className="text-gray-600">Privacy Policy</Link>
                                <Link to="/terms" className="text-gray-600">Terms & Conditions</Link>
                                {/* <a href=""> <li className="text-gray-600">Terms & Conditions</li></a> */}

                                <li className="text-gray-600">Refund & Cancellation</li>
                                <li className="text-gray-600">Investors</li>
                                <li className="text-gray-600">About Us</li>
                                <li className="text-gray-600">Partner With Us</li>
                            </ul>
                        </div>
                        <div className='text-start'>
                            <h3 className="text-xl font-bold text-green">Contact</h3>
                            <ul className="mt-2 space-y-2">
                                <li className="text-gray-600"> +91 8667211896</li>
                                <li className="text-gray-600">Get In Touch With Us</li>
                                <li className="text-gray-600">getintouch@thegenie.in</li>
                                <li className="text-gray-600">GENERIC TECHNOLOGIES PRIVATE LIMITED <br />

                                    No.32, Arcot Road,

                                    Virugambakkam,

                                    Chennai - 600092.

                                    India</li>
                            </ul>
                        </div>
                    </div>
                </footer>
            </div>

        </>
    )
}

export default Terms
